import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Box from '~v2/elements/container/box';

import Media from '~v2/blocks/media';
import Slider from '~v2/blocks/slider';

import ChevronsUrl from '!url-loader!images/svg/symbols/4-b.svg';
import MEDIA from 'helpers/mediaTemplates';

const UserSlider = ({ items, bgImage }) => (
  <SliderContainer>
    <Box noPadding={true}>
      <Slider items={items} />
      <div className="slider-bg">
        <Media
          className="chevron-mask"
          fluid={bgImage ? bgImage.childImageSharp.fluid : {}}
          height="800px"
        />
      </div>
    </Box>
  </SliderContainer>
);

UserSlider.propTypes = {
  items: PropTypes.shape([]).isRequired,
  bgImage: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({}),
    }),
  }),
};

export default UserSlider;

export const SliderContainer = styled.section`
  position: relative;

  .slider-bg {
    position: absolute;
    left: -410px;
    top: -87px;
    width: 1400px;
    transform: scale(1.5);

    .chevron-mask {
      mask-position: center center;
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-image: url(${ChevronsUrl});
    }

    ${MEDIA.PHONE`
      display: none
    `};

    ${MEDIA.TABLET`
      left: -510px;
      ${'' /* display: none */}
    `};

    ${MEDIA.MIN_DESKTOP`
      left: -33%;
      top: -2.5%;
      width: 1200px;
    `};

    ${MEDIA.MIN_LG_DESKTOP`
      left: -175px;
    `};
  }
`;
