import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'nuka-carousel';
import SquareSVG from 'images/svg/shapes/square.svg';
import { Container } from './slider.css';
import Img from 'gatsby-image';

const Slider = ({ items, bgImage, sliderWidth = '100%' }) => {
  const [slideIndex, setIndex] = useState(0);

  return (
    <Container className="carousel-container">
      <div className="bg-container">
        <div className="image-container app-screen-bg">
          <Img fluid={bgImage ? bgImage.childImageSharp.fluid : {}} />
        </div>
      </div>
      <Carousel
        slideIndex={slideIndex}
        slidesToShow={1}
        autoplay={true}
        width={sliderWidth}
        wrapAround={true}
        cellSpacing={128}
        autoplayInterval={10000}
        // slideWidth={'200px'}
        afterSlide={index => setIndex(index)}
        renderCenterLeftControls={({ previousSlide }) => (
          <button className="slide-controls prev" onClick={previousSlide}>
            <SquareSVG />
          </button>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <button className="slide-controls next" onClick={nextSlide}>
            <SquareSVG />
          </button>
        )}
        renderBottomCenterControls={null}
      >
        {items &&
          items.map((item, index) => {
            return (
              <div className="slide-container" key={index.toString()}>
                <div className="image-container">
                  <Img
                    fluid={item.image ? item.image.childImageSharp.fluid : {}}
                  />
                </div>
              </div>
            );
          })}
      </Carousel>
    </Container>
  );
};

Slider.propTypes = {
  items: PropTypes.shape([
    {
      image: PropTypes.shape({}),
    },
  ]),
  title: PropTypes.string,
  desc: PropTypes.string,
  bgImage: PropTypes.object,
  sliderWidth: PropTypes.string,
};

export default Slider;
