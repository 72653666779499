import styled from 'styled-components';
import { colors } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

export const TabContainer = styled.section`
  position: relative;
  display: flex;

  box-sizing: border-box;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  background-color: ${props => props.bgColor || colors.white};

  color: ${props => {
    if (props.fontColor) {
      return props.fontColor;
    }

    switch (props.bgColor) {
      case colors.warm_grey:
        return colors.cool_grey;
      case colors.dusty_gold:
        return colors.white;
      default:
        return colors.trace_black;
    }
  }};

  .btn-solid.btn-dusty_gold {
    border-color: ${colors.white};

    &:hover {
      color: ${colors.white};
    }
  }

  .react-tabs__tab--selected {
    .btn.btn-solid {
      background-color: ${props => {
        switch (props.bgColor) {
          case colors.warm_grey:
            return colors.cool_grey;
          case colors.dusty_gold:
            return colors.white;
          default:
            return colors.trace_black;
        }
      }};

      color: ${props => {
        switch (props.bgColor) {
          case colors.warm_grey:
            return colors.warm_grey;
          case colors.dusty_gold:
            return colors.dusty_gold;
          default:
            return colors.white;
        }
      }};

      border-color: ${props => {
        switch (props.bgColor) {
          case colors.warm_grey:
            return colors.cool_grey;
          case colors.dusty_gold:
            return colors.white;
          default:
            return colors.trace_black;
        }
      }};

      border-color: ${props => {
        switch (props.bgColor) {
          case colors.warm_grey:
            return colors.cool_grey;
          case colors.dusty_gold:
            return colors.white;
          default:
            return colors.trace_black;
        }
      }};
    }
  }
`;

export const TabButtonContainer = styled.div`
  margin: 2rem auto;
  white-space: nowrap;

  ${MEDIA.MIN_DESKTOP`
    width: 400px;
  `};
`;
