import styled from 'styled-components';
import { palette, colors } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  position: relative;
  margin: 0 auto;
  ${'' /* width: 280px;
  ${MEDIA.MIN_TABLET`
    width: 320px;
  `}; */}

  .bg-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    .image-container {
      max-width: 100%;
      ${'' /* width: 380px; */}
      height: auto;
      margin: 0 auto;
    }
  }

  .slider {
    margin: 30px auto 60px;
    z-index: 15;
    max-width: 390px;
    ${'' /* padding-bottom: 55px; */}
    ${'' /* width: 88% !important; */}

    ${MEDIA.PHONE`
      margin-top: -4rem;
    `};

  }

  .slider-frame {
    overflow: hidden;
    z-index: 5;
  }


  .slider .image-container {
    max-width: 100%;
    ${'' /* width: 330px; */}
    height: auto;
    margin: 0 auto;
  }

  .slider .paging-item {
    .paging-dot {
      background: #ccc none repeat scroll 0% 0% !important;
    }

    &.active {
      .paging-dot {
        background: #999 none repeat scroll 0% 0% !important;
      }
    }
  }

  .slide-controls {
    background: none;
    border: none;
    cursor: pointer;
    padding: 2rem;
    outline-color: #3a3a3a;
    ${MEDIA.PHONE`
      display: none;
    `};

    &:hover {
      svg rect {
        stroke: ${colors.spanish_white}!important;
        ${'' /* fill: transparent !important; */}
      }
    }

    rect {
      stroke-width: 25px !important;
      stroke: ${colors.dusty_gold}!important;
      fill: ${colors.dusty_gold}!important;
    }

    &.prev {
      svg {
        transform: rotateZ(45deg);
      }
    }

    &.next {
      svg {
        transform: rotateZ(45deg);
      }
    }
  }

  .feature-item {
    display: none;
    ${MEDIA.PHONE`
      display: block;
      background: ${palette.offBlack};
      position: absolute;
      bottom: 0;
    `};
  }

  .slider-control-centerleft,
  .slider-control-centerright {
    z-index: 0;
  }

  .slider-control-centerright {
    right: -40% !important;

    ${MEDIA.TABLET`
      right: -28% !important;
    `};

    ${MEDIA.MIN_DESKTOP`
      right: -30% !important;
    `};
  }

  .slider-control-centerleft {
    left: -40% !important;

    ${MEDIA.TABLET`
      left: -28% !important;
    `};

    ${MEDIA.MIN_DESKTOP`
      left: -30% !important;
    `};
  }
`;

export const FeatureItem = styled.div`
  width: 100%;
`;
