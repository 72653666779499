import React from 'react';
import PropTypes from 'prop-types';
import Grid from 'hedron';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Box from '~v2/elements/container/box';
import Title from '~v2/elements/title';
import Copy from '~v2/elements/copy';
import Button from '~v2/elements/button';

import { TabContainer, TabButtonContainer } from './tabs.css';

const TabbedContent = ({ tabs, containerBgColor }) => {
  return (
    <TabContainer bgColor={containerBgColor}>
      <Tabs style={{ width: '100%', 'max-width': '1280px' }}>
        <Grid.Bounds
          valign="center"
          halign="center"
          TABLET_DOWN={{ direction: 'vertical', padding: '0' }}
          TABLET_UP={{ direction: 'horizontal' }}
          TABLET={{ padding: '2rem' }}
          DESKTOP={{ padding: '4rem 2rem' }}
          LG_DESKTOP={{ padding: '4rem 0' }}
        >
          <Grid.Box
            className="tablet-order-plus-one"
            fluid={true}
            TABLET_DOWN={{ width: '100%', padding: '0 2rem 2rem' }}
            TABLET_UP={{ width: 'auto' }}
            DESKTOP_UP={{ margin: '0 4rem 0 0' }}
          >
            <Box noPadding={true} containerMargin="0">
              <TabList>
                {tabs.map(tab => (
                  <Tab key={`tabTitle-${tab.title}`}>
                    <TabButtonContainer>
                      <Button
                        size="lg"
                        color={containerBgColor}
                        buttonWidth="100%"
                      >
                        {tab.tabTitle}
                      </Button>
                    </TabButtonContainer>
                  </Tab>
                ))}
              </TabList>
            </Box>
          </Grid.Box>
          <Grid.Box
            style={{}}
            PHONE={{ margin: '0 0 -0.5rem' }}
            TABLET_DOWN={{
              width: '100%',
              padding: '2rem 2rem 0',
            }}
            TABLET={{ margin: '0 0 0 2rem' }}
            TABLET_UP={{ width: 'auto', paddingTop: '3rem' }}
          >
            <Box noPadding={true}>
              {tabs.map(tab => (
                <TabPanel key={`tabPanel-${tab.title}`}>
                  <Title
                    variant="subheading"
                    as="h2"
                    fontSize="3rem"
                    innerHtml={true}
                  >
                    {tab.title}
                  </Title>
                  <Copy size="lg" marginBottom="0">
                    {tab.content}
                  </Copy>
                </TabPanel>
              ))}
            </Box>
          </Grid.Box>
        </Grid.Bounds>
      </Tabs>
    </TabContainer>
  );
};

TabbedContent.propTypes = {
  tabs: PropTypes.shape([]),
  containerBgColor: PropTypes.string,
};

export default TabbedContent;
