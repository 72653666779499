import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

// Core components
import Layout from 'components/layout';
import Head from 'components/head';

// v2 components
import Section from '~v2/section';
import Tabs from '~v2/blocks/tabs';
import Content from '~v2/blocks/content';

import UserSlider from '~v2/pages/users/userSlider';

// import Wrapper from '~v2/elements/container/wrapper';
import Box from '~v2/elements/container/box';
import Copy from '~v2/elements/copy';
import { colors } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

const IFrameContainer = styled.div`
  iframe {
    height: auto;
    min-height: 205px;

    ${MEDIA.PHONE`
      margin: 2rem 0;
    `};

    ${MEDIA.MIN_PHONE`
      margin: 2rem 0;
      min-height: 240px;
    `};

    @media (min-width: ${760 / 16}em) and (max-width: ${770 / 16}em) {
      min-height: 395px;
    }

    ${MEDIA.MIN_DESKTOP`
      margin: 2rem 0;
      min-height: 395px;
    `};

    ${MEDIA.MIN_LG_DESKTOP`
      min-height: 480px;
    `};
  }
`;

const Download = ({
  data: {
    downloadJson: {
      title,
      hero,
      // phone_images,
      user_tabs,
      header_slider,
      // download,
    },
    // site: {
    //   siteMetadata: { appStoreUrl },
    // },
  },
}) => (
  <Layout>
    <Head pageTitle={title} />
    <Head
      pageTitle={title}
      pageDescription="Our cutting edge decentralized technology provides tracking software that adds security and vital differentiation for all participants of the hemp industry."
    />
    <Section
      boundsProps={{
        fluid: true,
        style: { width: '100%' },
        valign: 'center',
        DESKTOP_DOWN: { direction: 'vertical', overflow: 'hidden' },
        DESKTOP_UP: {
          direction: 'horizontal',
          margin: '6rem 0 0',
          minHeight: '70vh',
        },
      }}
      left={{
        children: (
          <Box containerWidth="350px" containerMargin="0">
            <Content
              header={{
                type: 'h1',
                text: hero.title,
              }}
              // button={{
              //   style: 'solid',
              //   color: colors.dusty_gold,
              //   text: 'Download the App',
              //   to: appStoreUrl,
              // }}
            >
              <>{hero.tagline && <Copy>{hero.tagline}</Copy>}</>
            </Content>
          </Box>
        ),
        boxProps: {
          fluid: true,
          fill: true,
          // shiftRight: true,
          TABLET_DOWN: { width: '100%' },
          TABLET: { width: '100%' },
          DESKTOP: { width: '40%' },
          LG_DESKTOP: { width: '30%' },
        },
      }}
      right={{
        children: (
          <UserSlider
            items={header_slider.items}
            bgImage={header_slider.bgImage}
          />
        ),
        boxProps: {
          fluid: true,
          fill: true,
          TABLET_DOWN: { width: '400px', margin: '0 auto' },
          TABLET: { width: '600px', margin: '0', shiftDown: true },
          DESKTOP: { margin: '0 -15% 0 8rem' },
          DESKTOP_UP: { width: '688px' },
          LG_DESKTOP: { margin: '3rem -5rem 0 -5rem' },
        },
      }}
    />

    <Tabs tabs={user_tabs} containerBgColor={colors.dusty_gold} />

    <Section
      boundsProps={{
        fluid: true,
        style: { width: '100%' },
        valign: 'center',
        TABLET_DOWN: { margin: '4rem 0' },
        DESKTOP_DOWN: { direction: 'vertical', overflow: 'hidden' },
        DESKTOP_UP: {
          direction: 'horizontal',
          minHeight: '70vh',
          margin: '3rem 0 6rem',
        },
        LG_DESKTOP_UP: {
          margin: '6rem 0',
        },
      }}
      containerProps={{
        fontColor: colors.dusty_gold,
      }}
      right={{
        children: (
          <Box>
            <Content
              header={{
                type: 'h1',
                // eslint-disable-next-line quotes
                text: "The Problem We're Solving",
              }}
            ></Content>
          </Box>
        ),
        boxProps: {
          fluid: true,
          fill: true,
          // shiftRight: true,
          TABLET_DOWN: { width: '100%' },
          TABLET: { margin: '0 0 4rem' },
          DESKTOP: { width: '40%' },
          LG_DESKTOP: { width: '30%' },
          // style: { textAlign: 'center' },
        },
      }}
      left={{
        children: (
          <IFrameContainer>
            <iframe
              title="Trace - Join the Collective Vimeo video"
              src="https://player.vimeo.com/video/359143927"
              width="100%"
              height="auto"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            ></iframe>
          </IFrameContainer>
        ),
        boxProps: {
          fluid: true,
          fill: true,
          className: 'phone-order-plus-one',
          TABLET_DOWN: {
            width: '100%',
            margin: '-4rem auto 0',
            padding: '0 2rem 2rem',
          },
          TABLET: { width: '100%', margin: '0', shiftDown: true },
          DESKTOP: { margin: '0 0 0 2rem' },
          DESKTOP_UP: { width: '50%' },
          LG_DESKTOP: { margin: '2rem 2rem 0 0' },
        },
      }}
    />
    {/* 
    <Section
      boundsProps={{
        fluid: true,
        style: { width: '100%' },
      }}
      containerProps={{
        bgColor: colors.cool_grey,
      }}
      left={{
        children: (
          <Box containerWidth="280px" containerMargin="3rem auto">
            <Media
              fixed={
                download.productImage
                  ? download.productImage.childImageSharp.fixed
                  : {}
              }
            />
          </Box>
        ),
        boxProps: {
          fluid: true,
          shiftRight: true,
          TABLET_DOWN: { width: '40%' },
          TABLET_UP: { width: '50%' },
        },
      }}
      right={{
        children: (
          <Box>
            <Content
              header={{
                type: 'h1',
                text: download.title,
              }}
              button={{
                style: 'solid',
                color: colors.warm_grey,
                text: 'Download the App',
                to: '#download',
              }}
            >
              <Copy>{download.desc}</Copy>
            </Content>
          </Box>
        ),
        boxProps: {
          fluid: true,
          TABLET_DOWN: { width: '60%' },
          TABLET_UP: { width: '50%' },
        },
      }}
    /> */}
  </Layout>
);

Download.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Download;

export const query = graphql`
  query DownloadQuery {
    site {
      siteMetadata {
        appStoreUrl
        playStoreUrl
      }
    }
    downloadJson {
      title
      hero {
        title
      }
      phone_images {
        iphone5 {
          childImageSharp {
            fixed(width: 320) {
              ...GatsbyImageSharpFixed
            }
            fluid(maxHeight: 600, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      user_tabs {
        tabTitle
        title
        content
      }
      header_slider {
        items {
          image {
            childImageSharp {
              fluid(maxHeight: 800, quality: 80) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
        bgImage {
          childImageSharp {
            fluid(maxHeight: 760, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      download {
        title
        desc
        productImage {
          childImageSharp {
            fluid(maxHeight: 800, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
